const resource = {
    en: {
        request_password_reset: 'Request Password Reset',
        email_address: 'Email Address',
        request_button: 'Request'
    },
    de: {
        request_password_reset: 'Passwort zurücksetzen',
        email_address: 'Email Address',
        request_button: 'Anfordern'
    }
}

export default resource;