<template>
    <form class="authentication-card" @submit.prevent="requestResetPassword">
        <h1 class="authentication-heading" v-html="resource[lang].request_password_reset" />
        <AuthenticationInputText required
            class="authentication-input" 
            v-model="email" 
            :placeholder="resource[lang].email_address"
        />
        <Button stretch rounded submit
            class="authentication-button" 
            color="accent" 
            :label="resource[lang].request_button" 
        />
    </form>
</template>

<script>
import { useQuasar } from 'quasar'

import resource from '@/assets/resources/forgot-password-resource'

import AuthenticationInputText from '@/components/basic/AuthenticationInputText'
import Button from '@/components/basic/Button'

import { promisePostRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        AuthenticationInputText,
        Button
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            email: '',

            resource
        }
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    methods: {
        // Requests
        requestResetPasswordRequest() {
            const request_body = {
                email: this.email,
            }
            return promisePostRequest('forgot-password', request_body);
        },

        // Actions
        async requestResetPassword() {
            this.$store.dispatch('loading', true);
            const response = await this.requestResetPasswordRequest();
            this.$store.dispatch('loading', false);

            if (response.status === 'success' || response.status === 200) {
                this.$router.push('/forgot-password-confirmation')
            } else {
                if (response.response.status == 422) {
                    for (let issue in response.response.data) {
                        this.quasar.notify({
                            position: 'bottom-left', 
                            message: `${issue}: ${response.response.data[issue]}`, 
                            color: 'negative'
                        });
                    }
                } else {
                    this.quasar.notify({
                        position: 'bottom-left', 
                        message: 'service not available', 
                        color: 'negative'
                    });
                }
            }
        }
    }
}
</script>